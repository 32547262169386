<template>
    <div class="profile-wrapper">
        <h1>Profile</h1>
        <h3></h3>

        <form>
            <div class="input-group">
                <input type="text" v-model="user.full_name" name="full_name" id="full_name">
                <label for="full_name">Your name</label>
            </div>
        </form>

        <button class="btn primary" @click="logout">Logout</button>
    </div>
</template>

<script>
// import AuthService from "@/services/AuthService";
import { mapGetters } from "vuex";

export default {
    name: 'profile',
    data() {
        return {
            user: {
                full_name: '',
                email: '',
                bio: '',
                username: ''
            }
        }
    },
    async created() {
        if(!this.$store.getters.isAuthenticated) {
            this.$router.push({ name: 'login' })
        }

        this.user.username = this.$store.getters.currentUser.username;
        this.user.full_name = this.$store.getters.currentUser.full_name;
        this.user.email = this.$store.getters.currentUser.email;
        this.user.bio = this.$store.getters.currentUser.bio;
    },
    methods: {
        logout() {
            this.$store.dispatch('logout');
            this.$router.push({ name: 'login' });
        }
    },
    computed: {
        ...mapGetters([ 'isAuthenticated' ])
    }
}
</script>

<style>

</style>